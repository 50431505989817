import React from "react";
import "./Row.css";
import { snackForIndex } from "../model/Snacks";

const Row = ({
  index,
  style,
}: {
  index: number;
  style: React.CSSProperties;
}) => (
  <div style={style} className="row">
      {/*TODO add some spacing inbetween row items*/}
      {/*can be done by not making this image take up the full div size,*/}
      {/*but that will require the same change to be made in the "selectedImage" image*/}
    <img
      className="rowImage"
      src={snackForIndex(index).image}
      alt={snackForIndex(index).name}
    />
  </div>
);

export default Row;
