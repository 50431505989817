import "./App.css";
import AutoSizer, {Size} from "react-virtualized-auto-sizer";
import {FixedSizeList as List} from "react-window";
import React, {useRef, useEffect} from "react";
import {useSpring, easings} from "@react-spring/web";
import Row from "./components/Row";
import {numberOfSnacks, preloadSnackImages, snackForIndex} from "./model/Snacks";
import imageLogo from './assets/images/logo_lelijk.png';
// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics, logEvent} from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC-k1cSfVCAJJqXehmqAKT8DLYM4tzNqdM",
    authDomain: "frietroulette.firebaseapp.com",
    projectId: "frietroulette",
    storageBucket: "frietroulette.firebasestorage.app",
    messagingSenderId: "177534586659",
    appId: "1:177534586659:web:b2bd281289037daec30696",
    measurementId: "G-G2EY7NR4EL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const itemHeight = 170;

enum ScreenState {
    INTRO,
    SCROLLING,
    SELECTED,
}

function App() {
    // Active page
    const [screenState, setScreenState] = React.useState(ScreenState.INTRO);
    // Scrolling logic
    const listRef = useRef<List>(null);
    // todo can we use one of the other hooks to get rid of this unused scrollSpring?
    const [, setScrollSpring] = useSpring(() => ({scrollTop: 0}));
    const [targetIndex, setTargetIndex] = React.useState(0);
    // Animations:
    const [isListFadedOut, setIsListFadedOut] = React.useState(false);
    const [isOverlayUiVisible, setIsOverlayUiVisible] = React.useState(false);
    const [isOverlayContentInPlace, setIsOverlayContentInPlace] = React.useState(false);

    function startInitialRoll() {
        setScreenState(ScreenState.SCROLLING);
    }

    useEffect(() => {
        preloadSnackImages()
    }, []);

    useEffect(() => {
        if (screenState === ScreenState.SCROLLING) {
            calculateNextTargetIndex();
        }
    }, [screenState]);

    useEffect(() => {
        const scrollToItem = (index: number) => {
            if (!listRef.current) return;

            const extraOffset = ((listRef.current.props.height as number) - itemHeight) / 2;
            const targetScrollTop = index * itemHeight - extraOffset;

            setScrollSpring.start({
                scrollTop: targetScrollTop,
                config: {
                    duration: 4000,
                    // TODO tweak so the ending is a bit slower and more exciting
                    easing: easings.easeOutCubic,
                },
                onChange: ({value}) => {
                    listRef.current?.scrollTo(value.scrollTop);
                },
                onResolve: () => {
                    setScreenState(ScreenState.SELECTED);
                    setIsListFadedOut(true);
                    setIsOverlayUiVisible(true);
                    setIsOverlayContentInPlace(true);
                }
            });
        };

        if (targetIndex > 0) {
            scrollToItem(targetIndex);
        }
    }, [targetIndex, setScrollSpring]);

    function reroll() {
        console.log("logging reroll event");
        logEvent(analytics, "reroll", {
            // TODO when adding translations: track a language-agnostic key
            snack: snackForIndex(targetIndex).name
        });
        setIsOverlayContentInPlace(false);
        setIsListFadedOut(false);
        // Wait for animations to finish before hiding the overlay
        // TODO use callbacks on the animations so we don't have to hardcode the animation duration here
        setTimeout(() => {
            setIsOverlayUiVisible(false);
            setScreenState(ScreenState.SCROLLING);
        }, 250);
    }

    function calculateNextTargetIndex() {
        setTargetIndex((prevIndex) => {
            const totalSnacks = numberOfSnacks();
            const randomSnack = Math.floor(Math.random() * totalSnacks);
            // + totalSnacks to ensure we see at least one full spin of the wheel
            return prevIndex + totalSnacks + randomSnack;
        });
    }

    // todo can we split this into separate components?
    if (screenState === ScreenState.INTRO) {
        return <div className="pageIntro">
            <img className="intro_logo" src={imageLogo}/>
            <button className="buttonIntro" onClick={() => startInitialRoll()}>Vind mijn snack</button>
        </div>
    } else {
        return (
            <div className="pageRoulette">
                <div className={`list ${isListFadedOut ? "fade-out" : "fade-in"}`}>
                    {/* TODO do we really need this custom package just to make our list full size? */}
                    <AutoSizer>
                        {({width, height}: Size) => (
                            <List
                                height={height}
                                width={width}
                                itemCount={50000}
                                itemSize={itemHeight}
                                style={{
                                    // Don't let the user scroll the list themselves + hide the scrollbar.
                                    overflow: "hidden",
                                }}
                                ref={listRef}
                            >
                                {Row}
                            </List>
                        )}
                    </AutoSizer>
                </div>
                <div className={`overlay ${isOverlayUiVisible ? "visible" : "hidden"}`}>
                    <div
                        className={`snackSelected ${isOverlayContentInPlace ? "fade-in-fast-after-delay" : "fade-out-fast"}`}>
                        {/*TODO add some sort of "isSingular" property to a snack so we know when to drop the "een"*/}
                        <h2 className="snackIntro">Wat dacht je van een heerlijke<br/></h2>
                        <h1 className="snackName">{snackForIndex(targetIndex).name}</h1>
                    </div>
                    <div
                        className={`selectedSnackWrapper ${isOverlayContentInPlace ? "scale-up" : "scale-normal"}`}
                    >
                        <img
                            src={snackForIndex(targetIndex).image}
                            alt={snackForIndex(targetIndex).name}
                            className="selectedSnackImage"/>
                    </div>
                    <button
                        disabled={screenState !== ScreenState.SELECTED}
                        className={`buttonReroll ${isOverlayContentInPlace ? "fade-in-fast-after-delay" : "fade-out-fast"}`}
                        onClick={() => reroll()}>Toch liever iets anders
                    </button>
                </div>
            </div>
        );
    }
}

export default App;
